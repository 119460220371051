




















































































export default {
  name : 'EducationalProgram',
}
